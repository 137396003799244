import React from 'react';
import { IconButton, DefaultButton, Stack, Text, ActionButton } from '@fluentui/react';
import { makeStyles, Button as FluentUIButton } from "@fluentui/react-components";
import LoadingAnimation from 'icons/loading.svg';

type ButtonProps = {
  icon?: string | any;
  disabled?: boolean;
  iconOnly?: boolean;
  iconPosition?: 'before' | 'after';
  type?: 'button' | 'submit';
  size?: 'small' | 'medium' | 'large';
  subtle?: boolean;
  transparent?: boolean;
  loading?: boolean
  primary?: boolean;
  iconAppearance?: any;
  onClick?: () => void;
  children?: React.ReactNode;
  isVisible?: boolean;
  className?: string;
  form?:string;
};

export const Button: React.FC<ButtonProps> = ({ icon,
  iconOnly = false,
  iconPosition = 'before',
  type = 'button', size = 'medium',
  primary = false,
  iconAppearance='primary',
  form,
  loading = false, disabled = false,
  subtle = false, transparent = false, onClick, children, isVisible = true, className }) => {
  if (!isVisible)
    return (<></>)
  if (loading)
    return (
      <Stack className={className} horizontal verticalAlign={"center"} horizontalAlign={"space-between"} style={{ height: "28px", border: '1px dashed orange', padding: "5px" }} tokens={{ childrenGap: "5" }}>
        <object type="image/svg+xml" data={LoadingAnimation}>loading</object>
        <Text variant={"small"} style={{ color: "#fc6924" }}>Loading...</Text>
      </Stack>
    )
  else if (iconOnly)
    return (
      <IconButton className={className} primary={primary} iconProps={(icon) ? { iconName: ((typeof icon) === "string") ? icon : icon?.props?.iconName } : null} disabled={disabled} onClick={onClick} />
    )
  else if (transparent)
    return (
      <ActionButton className={className} iconProps={(icon) ? { iconName: ((typeof icon) === "string") ? icon : icon?.props?.iconName } : null} onClick={onClick} disabled={disabled}>
        {children}
      </ActionButton>
    )
  else if (primary) {
    return (
      <FluentUIButton className={className} type={type} form={form} disabled={disabled} icon={icon} appearance={iconAppearance} onClick={onClick}>{children}</FluentUIButton>
    );
  } else
    return (
      <DefaultButton className={className} type={type} disabled={disabled} iconProps={(icon) ? { iconName: ((typeof icon) === "string") ? icon : icon?.props?.iconName } : null} onClick={onClick}>{children}</DefaultButton>
    )
};