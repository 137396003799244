import React from 'react';
// import { mergeStyleSets,getTheme  } from '@fluentui/react';
import { UserMenu } from './UserMenu';

export const TopMenu : React.FunctionComponent<any> = () =>{
  // const theme = getTheme();
  // const classNames = mergeStyleSets({
  //   root: {
  //     borderBottomStyle: 'solid',
  //     borderBottomColor: theme.semanticColors.bodyFrameDivider,
  //     borderBottomWidth: 1,
  //     padding: theme.spacing.s1,
  //     height: 60
  //   }
  // });
  return (
    <div className='top-menu'>
      <UserMenu />
    </div>
  );
}
