import Keycloak from 'keycloak-js';

const clientId = process.env.REACT_APP_CLIENT_ID
const realm = process.env.REACT_APP_KEYCLOAK_REALM
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

const keycloakConfig = new Keycloak({
  url: 'https://kc-corp.poorvika.com',
  realm: realm,
  clientId: clientId,
});

export default keycloakConfig;