import React from "react";
import { IContextualMenuProps, CommandBarButton } from "@fluentui/react";
import Select from "react-select";
import { Label } from "@fluentui/react-components";
import { useAuthentication } from "util/authentication";
import { AxiosHTTPClient } from "util/AxiosInstance";
import { useHistory } from "react-router-dom";
import { useConst } from "@fluentui/react-hooks";
import { useKeycloak } from "@react-keycloak/web";
// import type { KeycloakInstance } from "keycloak-js";
import {
  IFailureResponse,
  ISuccessResponse,
  ResponseType,
} from "util/Constant";
import { ILocale, LocaleService } from "service/locale/LocaleService";
import { Button } from "components/Button";
import { ArrowCircleLeft32Filled } from "@fluentui/react-icons";
import "./topmenu.css";
export const UserMenu: React.FunctionComponent = () => {
  const { keycloak } = useKeycloak();
  const { principal, settings, resetLocale, logout } = useAuthentication();
  const axiosInstance = AxiosHTTPClient.getAxiosWithToken();
  const localeService: LocaleService = new LocaleService(axiosInstance);
  const [localeOptions, setLocaleOptions] = React.useState([]);
  const [locale, setLocale] = React.useState<string>("");
  const onBack = () =>{
    window.history.back();
  }
  const logoutUser = () => {
    if (keycloak?.authenticated) {
      keycloak.clearToken();
      keycloak.logout();
      logout();
    } else {
      logout();
    }
  };
  const history = useHistory();
  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    items: [
      {
        key: "profile",
        text: "Profile",
        iconProps: { iconName: "PlayerSettings" },
        onClick: () => history.push("/profile"),
      },
      {
        key: "logout",
        text: "Logout",
        iconProps: { iconName: "SignOut" },
        onClick: logoutUser,
      },
    ],
  }));

  React.useEffect(() => {
    console.log("Running effect once on UserMenu");
    (async function loadContent() {
      try {
        let localeRes: ISuccessResponse | IFailureResponse =
          await localeService.list({ isActive: true });
        if (localeRes.type == ResponseType.SUCCESS) {
          var locales: Array<ILocale> = localeRes.msg;
          setLocaleOptions(
            locales.map((locale) => ({
              value: locale.code,
              label: locale.name,
            }))
          );
          setLocale(settings?.default?.locale);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {
      console.log("Running clean-up of effect on unmount Usermenu");
    };
  }, []);

  return (
    <div className='flex-spacebt'>
      <div className='menu-backbutton'>
        <Button onClick={onBack} className='menu-back'>
          <ArrowCircleLeft32Filled />
          <span>Back</span>
        </Button>
      </div>
      <div className='menu-profile'>
        <div style={{ width: "200px" }}>
          {/* <Label weight='semibold'>Locale</Label> */}
          <Select
            style={{ width: "200px" }}
            placeholder='Select an Locale'
            isSearchable={false}
            isMulti={false}
            value={localeOptions.filter((item) => item.value === locale)}
            options={localeOptions}
            onChange={async (selectedOption) => {
              resetLocale(selectedOption.value.toString());
              setLocale(selectedOption.value.toString());
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "neutral20",
                primary: "#ff9000",
              },
            })}
          />
        </div>
        <CommandBarButton
          menuProps={menuProps}
          iconProps={{ iconName: "UserOptional" }}
        >
          {principal?.displayName}
        </CommandBarButton>
      </div>
    </div>
  );
};
