import React from 'react';
import { IChannel } from 'service/channel/ChannelService';

interface ChannelContextProps {
  channel: Partial<IChannel>;
  updChannelContext : (channel : IChannel) => void;
};

const defaultValues = {
  channel: {},
  updChannelContext: () => {},
};

const ChannelContext = React.createContext<ChannelContextProps>(defaultValues);
export const ChannelContextProvider = ChannelContext.Provider;

export const ChannelProvider = ({children}) => {
  const [channel, setChannel] = React.useState<IChannel>();
  const updChannelContext = async (channel) => await setChannel(channel);
  const values = {channel,updChannelContext};
  return (
    <ChannelContextProvider value={values}>
      {children}
    </ChannelContextProvider>
  );
}

export function useChannel() {
  return React.useContext(ChannelContext);
}
