import React from "react";
import { mergeStyleSets, getTheme, Stack } from "@fluentui/react";
import { Divider } from "@fluentui/react-components";
import { Sidebar } from "../Sidebar";
import { TopMenu } from "../TopMenu";

export const MasterLayout: React.FunctionComponent<any> = ({ children }) => {
  const theme = getTheme();
  const classNames = mergeStyleSets({
    root: {},
    sidebar: {},
    contentWrapper: {
      paddingLeft: theme.spacing.l2,
      paddingRight: theme.spacing.l2,
      paddingTop: theme.spacing.l1,
    },
  });
  return (
    <Stack horizontal className={classNames.root}>
      <Stack.Item grow={false} className={classNames.sidebar}>
        <Sidebar />
      </Stack.Item>
      <Stack.Item grow={true} style={{ width: "80%" }}>
        <TopMenu />
        <Stack style={{ padding: "20px" }}>{children}</Stack>
      </Stack.Item>
    </Stack>
  );
};
